<template>
  <iframe src="https://dify.twirlingai.com/chatbot/xFLEqXbCW3pcB0hc" allow="microphone"></iframe>
</template>

<script>
export default {
  name: 'ChatOne'
}
</script>

<style>
iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>